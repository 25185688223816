const sources = {
    runningLevel: {
        url: 'https://runninglevel.com/running-times',
        displayName: 'Running Level',
    },
    nycMarathon: {
        url: 'https://www.nyrr.org/tcsnycmarathon',
        displayName: 'NYRR',
    },
    bostonMarathon: {
        url: 'https://www.baa.org/races/boston-marathon/qualify',
        displayName: 'BAA',
    },
};

export const presets = [
    {
        displayName: 'NYC Marathon Qualifier, Half 2023 (M 18-34)',
        distance: 13.1,
        time: '1:21:00',
        pace: '6:11',
        source: sources.nycMarathon,
    },
    {
        displayName: 'NYC Marathon Qualifier 2023 (M 18-34)',
        distance: 26.2,
        time: '2:53:00',
        pace: '6:37',
        source: sources.nycMarathon,
    },
    {
        displayName: 'NYC Marathon Qualifier, Half 2023 (F 18-34)',
        distance: 13.1,
        time: '1:32:00',
        pace: '7:02',
        source: sources.nycMarathon,
    },
    {
        displayName: 'NYC Marathon Qualifier 2023 (F 18-34)',
        distance: 26.2,
        time: '3:13:00',
        pace: '7:22',
        source: sources.nycMarathon,
    },
    {
        displayName: 'Half Marathon World Record (M)',
        distance: 13.1,
        time: '58:01',
        pace: '4:26',
        source: sources.runningLevel,
    },
    {
        displayName: 'Half Marathon World Record (F)',
        distance: 13.1,
        time: '1:04:31',
        pace: '4:56',
        source: sources.runningLevel,
    },
    {
        displayName: 'Marathon World Record (M)',
        distance: 26.2,
        time: '2:01:39',
        pace: '4:38',
        source: sources.runningLevel,
    },
    {
        displayName: 'Marathon World Record (F)',
        distance: 26.2,
        time: '2:14:04',
        pace: '5:08',
        source: sources.runningLevel,
    },
    {
        displayName: '5k World Record (M)',
        distance: 3.1,
        time: '12:35',
        pace: '4:04',
        source: sources.runningLevel,
    },
    {
        displayName: '5k World Record (F)',
        distance: 3.1,
        time: '14:06',
        pace: '4:31',
        source: sources.runningLevel,
    },
    {
        displayName: 'Boston Marathon Qualifier 2023 (M 18-34)',
        distance: 26.2,
        time: '3:00:00',
        pace: '6:53',
        source: sources.bostonMarathon,
    },
    {
        displayName: 'Boston Marathon Qualifier 2023 (F 18-34)',
        distance: 26.2,
        time: '3:30:00',
        pace: '8:01',
        source: sources.bostonMarathon,
    },
    {
        displayName: 'NYC Marathon 2022 Winner (M)',
        distance: 26.2,
        time: '4:54',
        pace: '2:08:41',
        source: sources.nycMarathon,
    },
    {
        displayName: 'NYC Marathon 2022 Winner (F)',
        distance: 26.2,
        time: '5:28',
        pace: '2:23:23',
        source: sources.nycMarathon,
    },
    {
        displayName: 'NYC Marathon 2022 Winner (NB)',
        distance: 26.2,
        time: '6:19',
        pace: '2:45:12',
        source: sources.nycMarathon,
    },
    {
        displayName: 'NYC Marathon 2022 Average',
        distance: 26.2,
        time: '11:06',
        pace: '4:50:26',
        source: sources.nycMarathon,
    },
    {
        displayName: '5k Average - Beginner',
        distance: 3.1,
        time: '32:00',
        pace: '10:20',
        source: sources.runningLevel,
    },
    {
        displayName: '5k Average - Intermediate',
        distance: 3.1,
        time: '23:00',
        pace: '7:26',
        source: sources.runningLevel,
    },
    {
        displayName: '5k Average - Elite',
        distance: 3.1,
        time: '18:00',
        pace: '5:49',
        source: sources.runningLevel,
    },
    {
        displayName: 'Half Marathon Average - Beginner',
        distance: 13.1,
        time: '2:24:57',
        pace: '10:60',
        source: sources.runningLevel,
    },
    {
        displayName: 'Half Marathon Average - Intermediate',
        distance: 13.1,
        time: '1:50:15',
        pace: '8:24',
        source: sources.runningLevel,
    },
    {
        displayName: 'Half Marathon Average - Elite',
        distance: 13.1,
        time: '1:20:59',
        pace: '6:07',
        source: sources.runningLevel,
    },
    {
        displayName: 'Marathon Average - Beginner',
        distance: 26.1,
        time: '4:57:01',
        pace: '11:21',
        source: sources.runningLevel,
    },
    {
        displayName: 'Marathon Average - Intermediate',
        distance: 26.1,
        time: '3:34:56',
        pace: '8:13',
        source: sources.runningLevel,
    },
    {
        displayName: 'Marathon Average - Elite',
        distance: 26.1,
        time: '2:49:13',
        pace: '6:28',
        source: sources.runningLevel,
    },
].sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
